<template>
	<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
		<div class="container-fluid" @mouseenter="mouseIn = true" @mouseleave="mouseIn = false">
			<router-link class="navbar-brand" to="/">
				<img src="/images/logos/alphabatem.png" height="60" class="d-inline-block align-top" alt="logo">
			</router-link>
		</div>
	</nav>
</template>

<script>


export default {
	name: "Topnav",
	data() {
		return {
			activeMenu: "",
			mouseIn: false,
		}
	},
	watch: {
		'$route.path': function () {
			console.log("Route change", this.$route.path)
			this.activeMenu = ''
		}
	},
	methods: {
		closeMenu: function () {
			this.$nextTick(() => {
				if (this.mouseIn)
					return

				this.activeMenu = ''
			})
		}
	}
}
</script>

<style scoped>
.navbar-brand, .navbar-dark .navbar-nav .nav-link, .nav-item {
	font-weight: 400;
	color: white;
	transition: color 0.3s ease-in-out;
}

.nav-link:hover, .nav-link:active, .nav-link:focus {
	color: var(--btn-bg-primary) !important;
}

.nav-item .social-link img {
	width: 20px;
	margin: auto;
}

.nav-item .social-link {
	background: white;
	border-radius: 66%;
	padding: 5px;
	width: 36px;
	height: 36px;
}

.nav-item .social-link:hover {
	background: var(--link-primary);
}

.nav-item .social-link:hover img {
	filter: invert(1);
}

.nav-item span {
	cursor: pointer;
}
</style>