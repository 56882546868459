import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/HomeNext.vue')
	},
	{
		path: '/privacy',
		name: 'Privacy Policy',
		component: () => import(/* webpackChunkName: "terms" */ '../views/info/PrivacyPolicy.vue')
	},
	{
		path: '/terms-and-conditions',
		name: 'Terms and Conditions',
		component: () => import(/* webpackChunkName: "terms" */ '../views/info/TermsConditions.vue')
	},
	{
		path: "*",
		redirect: "/"
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				window.scrollTo(0, 0)
				resolve({})
			}, 100)
		})
	},
	routes
})

export default router
